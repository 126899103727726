import { IField, IPrice } from '@/client/types'
import {
  Button,
  Extra,
  Resume,
  Step8,
  Step9,
  Help,
  CalculatorStep,
  RandomText,
  ServiceModel,
  ArtworkServiceOptionModel
} from '@/client/types/Calculator'
import { z } from 'zod'
import { ErrorMessages } from '@/client/types/Calculator'

export type FieldType =
  | 'select-radio'
  | 'input-number'
  | 'select-radio-vertical'
  | 'textarea'
  | 'checkbox'
  | 'range'
  | 'select-card-checkbox'
  | 'select-radio-vertical'

export type CalculatorProps = {
  hasOpenClose?: boolean
  hasTitle?: boolean
  serviceType?: string
  isArtworkService?: boolean
}

export type Selection = {
  [key: string]: string | string[] | number | number[]
}

export type SettingsChildProps = {
  fields: IField[]
  texts: Step8
  step?: number
  wpText?: any
  randomText?: RandomText
  initialSelection?: any
  reset?: boolean
  setResetFalse?: () => void
  serviceType?: string
  isArtworkService?: boolean
  artworkServiceText: ArtworkServiceOptionModel
}

export enum Steps {
  SIZE_AND_FORMAT = 1,
  TYPE = 2,
  MATERIAL_AND_ADHESIVE = 3,
  COLOUR_AND_FINISHES = 4,
  REFINEMENT = 5,
  CUT = 6,
  MECHINE_PROCESSING = 7,
  OPTIONS = 8,
  SUMMARY = 9
}

export enum BasicProofSteps {
  SIZE_AND_FORMAT = 1,
  MATERIAL_AND_ADHESIVE = 2,
  COLOUR_AND_FINISHES = 3,
  OPTIONS = 4,
  SUMMARY = 5
}

export enum PremiumProofSteps {
  SIZE_AND_FORMAT = 1,
  MATERIAL_AND_ADHESIVE = 2,
  COLOUR_AND_FINISHES = 3,
  REFINEMENT = 4,
  MECHINE_PROCESSING = 5,
  OPTIONS = 6,
  SUMMARY = 7
}

export enum SmartServiceSteps {
  SIZE_AND_FORMAT = 1,
  MATERIAL_AND_ADHESIVE = 2,
  COLOUR_AND_FINISHES = 3,
  OPTIONS = 4,
  SUMMARY = 5
}

export enum ProServiceSteps {
  SIZE_AND_FORMAT = 1,
  MATERIAL_AND_ADHESIVE = 2,
  COLOUR_AND_FINISHES = 3,
  REFINEMENT = 4,
  OPTIONS = 5,
  SUMMARY = 6
}

export type StepStylesProps = {
  active: boolean
  finished?: boolean
}

export type SummaryChildProps = {
  calculatorFields: IField[]
  optionFields: IField[]
  setStep: (step: Steps) => void
  texts: Step9
  randomText?: RandomText
  isArtworkService: boolean
}

export type ResultProps = {
  result: IPrice
  step: Steps
  setStep: (step: Steps) => void
  setIsAlertOpen: (isAlertOpen: boolean) => void
  fields: IField[]
  texts: Resume
  error_messages?: ErrorMessages
}

export type ResultFooterProps = {
  step: Steps
  setStep: (step: Steps) => void
  setIsAlertOpen: (isAlertOpen: boolean) => void
  texts: {
    button: Button
    extra: Extra
    help: Help
    more_info: {
      info_sentence: string
    }
  }
  isLoading: boolean
  disableNextStep: boolean
}

export type ArtworkServiceOptionProps = {
  artwork_service_model: ArtworkServiceOptionModel
}

/*
 *
 * Calculator fields based on each steps are handled here.
 * Fetch calculator fields from price API based on the below steps and fields.
 *
 */
export type ServiceResultProps = {
  proofing_service_model: ServiceModel
  type?: any
}

export const getCalculatorConstants = (serviceType: string | undefined): CalculatorStep[] => {
  switch (serviceType) {
    case 'basic':
      return [
        {
          step: BasicProofSteps.SIZE_AND_FORMAT,
          fields: [{ key: 'quantity' }, { key: 'dimension' }, { key: 'diameter' }, { key: 'label_shape' }]
        },
        {
          step: BasicProofSteps.MATERIAL_AND_ADHESIVE,
          fields: [{ key: 'paper' }]
        },
        {
          step: BasicProofSteps.COLOUR_AND_FINISHES,
          fields: [{ key: 'colour' }]
        }
      ]
    case 'premium':
      return [
        {
          step: PremiumProofSteps.SIZE_AND_FORMAT,
          fields: [{ key: 'quantity' }, { key: 'dimension' }, { key: 'diameter' }, { key: 'label_shape' }]
        },
        {
          step: PremiumProofSteps.MATERIAL_AND_ADHESIVE,
          fields: [{ key: 'paper' }]
        },
        {
          step: PremiumProofSteps.COLOUR_AND_FINISHES,
          fields: [{ key: 'colour' }, { key: 'varnish' }]
        },
        {
          step: PremiumProofSteps.REFINEMENT,
          fields: [{ key: 'finishing' }, { key: 'foil' }]
        },
        {
          step: PremiumProofSteps.MECHINE_PROCESSING,
          fields: [{ key: 'machine_processing' }, { key: 'winding_direction' }]
        }
      ]
    case 'smart':
      return [
        {
          step: SmartServiceSteps.SIZE_AND_FORMAT,
          fields: [{ key: 'quantity' }, { key: 'dimension' }, { key: 'diameter' }, { key: 'label_shape' }]
        },
        {
          step: SmartServiceSteps.MATERIAL_AND_ADHESIVE,
          fields: [{ key: 'paper' }]
        },
        {
          step: SmartServiceSteps.COLOUR_AND_FINISHES,
          fields: [{ key: 'colour' }]
        }
      ]
    case 'pro':
      return [
        {
          step: ProServiceSteps.SIZE_AND_FORMAT,
          fields: [{ key: 'quantity' }, { key: 'dimension' }, { key: 'diameter' }, { key: 'label_shape' }]
        },
        {
          step: ProServiceSteps.MATERIAL_AND_ADHESIVE,
          fields: [{ key: 'paper' }]
        },
        {
          step: ProServiceSteps.COLOUR_AND_FINISHES,
          fields: [{ key: 'colour' }]
        },
        {
          step: ProServiceSteps.REFINEMENT,
          fields: [{ key: 'finishing' }, { key: 'foil' }]
        }
      ]
    default:
      return [
        {
          step: Steps.SIZE_AND_FORMAT,
          fields: [{ key: 'quantity' }, { key: 'dimension' }, { key: 'diameter' }, { key: 'label_shape' }]
        },
        {
          step: Steps.TYPE,
          fields: [{ key: 'label_type' }]
        },
        {
          step: Steps.MATERIAL_AND_ADHESIVE,
          fields: [{ key: 'adhesive' }, { key: 'paper' }]
        },
        {
          step: Steps.COLOUR_AND_FINISHES,
          fields: [{ key: 'colour' }, { key: 'opaque' }, { key: 'double_hit' }, { key: 'varnish' }]
        },
        {
          step: Steps.REFINEMENT,
          fields: [{ key: 'finishing' }, { key: 'blind_embossing' }, { key: 'foil' }]
        },
        {
          step: Steps.CUT,
          fields: [{ key: 'die' }]
        },
        {
          step: Steps.MECHINE_PROCESSING,
          fields: [
            { key: 'machine_processing' },
            { key: 'winding_direction' },
            { key: 'core_diameter' },
            { key: 'assembly' }
          ]
        }
      ]
  }
}

export const schema = (texts: ErrorMessages | undefined) => {
  const formSchema = z.object({
    quotation_cust_name: z.string().min(1, texts?.required),
    quotation_cust_mail: z.string().email(texts?.invalid_email)
  })
  return formSchema
}
